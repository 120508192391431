<template  >
  <div
    v-loading="loading"
    style="width: 100%"
    v-touch:swiperight="rightSlide"
  ></div>
</template>

<script>
import { getWxuserId, getSignature} from "@/api/api";
export default {
  name: "LoginCodes",
  data() {
    return {
      loading: true,
    };
  },
  directives: {
    //滑动指令
    touch: {
      bind: function (el, binding, vnode) {
        var touchType = binding.arg; //传入的模式 press swipeRight swipeLeft swipeTop swipeDowm Tap
        var timeOutEvent = 0;
        var direction = "";
        //滑动处理
        var startX, startY;

        //返回角度
        function GetSlideAngle(dx, dy) {
          return (Math.atan2(dy, dx) * 180) / Math.PI;
        }

        //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
        function GetSlideDirection(startX, startY, endX, endY) {
          var dy = startY - endY;
          var dx = endX - startX;
          var result = 0;

          //如果滑动距离太短
          if (Math.abs(dx) < 180 && Math.abs(dy) < 180) {
            return result;
          }

          var angle = GetSlideAngle(dx, dy);
          if (angle >= -45 && angle < 45) {
            result = "swiperight";
          } else if (angle >= 45 && angle < 135) {
            result = "swipeup";
          } else if (angle >= -135 && angle < -45) {
            result = "swipedown";
          } else if (
            (angle >= 135 && angle <= 180) ||
            (angle >= -180 && angle < -135)
          ) {
            result = "swipeleft";
          }
          return result;
        }

        el.addEventListener(
          "touchstart",
          function (ev) {
            startX = ev.touches[0].pageX;
            startY = ev.touches[0].pageY;

            //判断长按
            timeOutEvent = setTimeout(() => {
              timeOutEvent = 0;
              if (touchType === "press") {
                binding.value();
              }
            }, 500);
          },
          false
        );

        el.addEventListener("touchmove", function (ev) {
          clearTimeout(timeOutEvent);
          timeOutEvent = 0;
        });

        el.addEventListener(
          "touchend",
          function (ev) {
            var endX, endY;
            endX = ev.changedTouches[0].pageX;
            endY = ev.changedTouches[0].pageY;
            direction = GetSlideDirection(startX, startY, endX, endY);

            clearTimeout(timeOutEvent);

            switch (direction) {
              case 0:
                break;
              case "swipeup":
                if (touchType === "swipeup") {
                  binding.value();
                }
                break;
              case "swipedown":
                if (touchType === "swipedown") {
                  binding.value();
                }
                break;
              case "swipeleft":
                if (touchType === "swipeleft") {
                  binding.value();
                }
                break;
              case "swiperight":
                if (touchType === "swiperight") {
                  binding.value();
                }
                break;
              default:
            }
          },
          false
        );
      },
    },
  },
  methods: {
    rightSlide() {
      window.wx.closeWindow();
    },
    queryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    async getUserId(agentid, code, appid) {
      let form = {
        corpId: appid,
        agentid: agentid,
        code: code,
      };
      try {
        var result = await getWxuserId(form);
        let userInfo = JSON.parse(result);
        userInfo.userInfo = { ...JSON.parse(userInfo.userInfo) };
        userInfo.name = userInfo.userInfo.name + '(企业微信)'
        userInfo.phone = userInfo.userInfo.mobile
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));


        if (JSON.parse(sessionStorage.getItem("userInfo"))) {
          if (JSON.parse(sessionStorage.getItem("url"))) {
            this.loading = false;
            window.location.href = JSON.parse(sessionStorage.getItem("url"));
          } else {
            this.loading = false;
            // 新联账号
            // window.location.href = `http://reporttest.xlmediawatch.com/overviewJH?agentid=${agentid}&appid=${appid}`;

            // 上海家化账号
            window.location.href = `http://report.xlmediawatch.com/overviewJH?agentid=${agentid}&appid=${appid}`;
            // window.location.href = `http://reporttest.xlmediawatch.com/indexReports.html`;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    getQueryVariable(variable) {
      var query = window.location.href.split("?");
      if (query.length == 1) {
        return false;
      }
      query = window.location.href.split("?")[1];
      if (window.location.href.split("?").length > 2) {
        query = query + "&" + window.location.href.split("?")[2];
      }
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    getLoginMsg() {
      if(JSON.parse(sessionStorage.getItem("num"))){
        sessionStorage.setItem("num", JSON.stringify(2))
      }else{
        sessionStorage.setItem("num", JSON.stringify(1))
      }
      let agentid = this.getQueryVariable("agentid");
      let appid = this.getQueryVariable("appid");
      let user_code = this.queryString("code");
      sessionStorage.setItem("agentid", JSON.stringify(agentid));
      sessionStorage.setItem("appid", JSON.stringify(appid));
      // alert(user_code)
      if (user_code) {
        this.getUserId(agentid, user_code, appid);
      } else {
        this.loading = false;
        let router = "LoginCodes";
        // let url = `http%3A%2F%2Freporttest.xlmediawatch.com%2F%23%2F${router}%3Fagentid%3D${agentid}%26appid%3D${appid}`
        // 新联账号
        // let url = `http%3A%2F%2Freporttest.xlmediawatch.com%2F${router}%3Fagentid%3D${agentid}%26appid%3D${appid}`;

        // 上海家化账号
        let url = `http%3A%2F%2Freport.xlmediawatch.com%2F${router}%3Fagentid%3D${agentid}%26appid%3D${appid}`;
        // let url = `http%3A%2F%2F192.168.0.244%3A88882F${router}%3Fagentid%3D${agentid}%26appid%3D${appid}`
        url =
          `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=` +
          url +
          `&response_type=code&scope=snsapi_base&$state=#wechat_redirect`;
        window.location.replace(
          `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=` +
            url +
            `&response_type=code&scope=snsapi_base&$state=#wechat_redirect`
        );
        // var xhr
        // if (window.XMLHttpRequest) {
        //      xhr = new XMLHttpRequest();
        // } else {
        //     // IE6-
        //      xhr = new ActiveXObject();
        // }
        // // let url = 'http://report.xlmediawatch.com/'
        // xhr.open("GET", `${url}`, true);
        // xhr.send();
        // xhr.onreadystatechange = function () {
        //     var result = xhr.response
        //
        //     if (xhr.readyState == 4 && xhr.status == 200) {
        //         // var result = xhr.response
        //         // console.log("result")
        //         // console.log(result)
        //         // alert(1)
        //     }
        // }
      }
    },
    //判断是否微信登录
    async isWxBrowser() {
      if (this.getQueryVariable("agentid") && this.getQueryVariable("appid")) {
        await this.getSignature(
          this.getQueryVariable("agentid"),
          this.getQueryVariable("appid")
        );
      }
      var ua = navigator.userAgent.toLowerCase();
      let isWxWork = ua.match(/WxWork/i) == "wxwork";

      if (isWxWork) {
        // alert('isWx')
        this.getLoginMsg();
      } else {
        //判断是否有code
        if (this.queryString("code")) {
          this.getLoginMsg();
        } else {


          console.log('result')
          this.$router.push({
            path: "/authorizeQRCodes",
            query: {
              appid: this.getQueryVariable("appid"),
              agentid: this.getQueryVariable("agentid"),
            },
          });
        }
      }
    },
    async getSignature(agentid, appid) {
      let url = window.location.href.split("#")[0];
      try {
        let data = {
          url: url,
          agentId: agentid,
        };
        console.log("data");
        console.log(data);
        let res = await getSignature(data);
        console.log(res);
        let result = JSON.parse(res);
        console.log(result);

        wx.agentConfig({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          corpid: appid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: Number(result.timestamp), // 必填，生成签名的时间戳
          nonceStr: String(result.nonce_str), // 必填，生成签名的随机串
          signature: String(result.signature), // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: [
            "closeWindow",
          ], //必填，传入需要使用的接口名称
          success: function (res) {
            if (
              JSON.parse(sessionStorage.getItem("userInfo")) &&
              JSON.parse(sessionStorage.getItem("from")) == "/overviewJH"
            ) {
              wx.closeWindow();
            }
          },
          fail: function (res) {
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.isWxBrowser();
    // window.location.href = `http://reporttest.xlmediawatch.com/indexReports.html`;
  },
};
</script>

<style scoped>
div {
  width: 100vw;
  height: 100vh;
}
.content > p {
  text-align: center;
  margin-top: 15vh;
  font-weight: 700;
  font-size: 20px;
}
</style>
